import DarkModeContext from "../contexts/DarkModeContext";
import React, { useEffect, useState } from "react";

const DarkModeProvider = ({ children }: DarkModeProviderProps) => {
  // The dark mode attribute is stored when the application is first loaded.
  const [darkMode, setDarkMode] = useState(JSON.parse(localStorage.darkMode));

  useEffect(() => {
    // Set scrollbar to match theme
    document.documentElement.style.overflow = "hidden";
    document.body.clientWidth;
    document.documentElement.style.colorScheme = darkMode ? "dark" : "light";
    if (darkMode) {
      document.documentElement.classList.add("dark");
    } else {
      document.documentElement.classList.remove("dark");
    }
    document.documentElement.style.overflow = "";
  }, [darkMode]);

  const toggleDarkMode = () => {
    const new_state = !darkMode;
    localStorage.setItem("darkMode", JSON.stringify(new_state));
    setDarkMode(new_state);
  };

  return (
    <DarkModeContext.Provider value={{ darkMode, toggleDarkMode }}>
      {children}
    </DarkModeContext.Provider>
  );
};

interface DarkModeProviderProps {
  children: React.ReactNode;
}

export default DarkModeProvider;
