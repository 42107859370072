import React from "react";

function Background() {
  return (
    <div
      id="background"
      className="bg-white dark:bg-black fixed top-0 left-0 h-screen w-screen -z-10 slow-transition"
    ></div>
  );
}

export default Background;
