import React from "react";

import Header from "../components/layout_elements/Header";
import Footer from "../components/layout_elements/Footer";
import Background from "../components/layout_elements/Background";
import SmoothScrollWrapper from "../components/SmoothScrollWrapper";

import DarkModeProvider from "../providers/DarkModeProvider";
import MobileMenuProvider from "../providers/MobileMenuProvider";
import Cursor from "../components/Cursor";
import SEO from "../components/layout_elements/SEO";

const Layout = (props: iProps) => {
  return (
    <DarkModeProvider>
      <SEO />
      <MobileMenuProvider>
        <Wrapper>{props.children}</Wrapper>
      </MobileMenuProvider>
    </DarkModeProvider>
  );
};

const Wrapper = (props: iProps) => {
  return (
    <>
      <Background />
      <SmoothScrollWrapper>
        <main
          className={`mx-auto px-5 sm:px-20 py-10 w-full sm:max-w-7xl slow-transition flex flex-col justify-between box-border`}
        >
          {/* <Cursor /> */}
          <Header />
          {props.children}
          <Footer />
        </main>
      </SmoothScrollWrapper>
    </>
  );
};

interface iProps {
  children: React.ReactNode;
}

export default Layout;
