import React from "react";
import SocialLinksContainer from "../SocialLinksContainer";

function Footer() {
  return (
    <footer className="text-stone-dark dark:text-white justify-center box-border select-none mt-20">
      <div className="flex flex-row flex-nowrap antialiased place-content-center">
        <p>&copy; {String(new Date().getFullYear())}</p>
        <p className="mx-2">|</p>
        <p>
          Designed and built by <span>me.</span>
        </p>
      </div>
    </footer>
  );
}

export default Footer;
