import React, { useEffect } from "react";
import useMobileMenu from "../../hooks/useMobileMenu";
import { motion } from "framer-motion";

const MobileMenuToggle = () => {
  const { mobileMenuOpen, toggleMobileMenu } = useMobileMenu();

  const topBarVariants = {
    hidden: { width: 0 },
    menuClosed: {
      width: "auto",
    },
    menuOpen: {
      width: ["0px", "0px", "32px"],
      rotate: [0, 45],
      translateY: [0, 6],
      transition: {
        delay: 0,
        duration: 0.1,
        type: "spring",
        mass: 0.01,
        stiffness: 10,
        damping: 1,
      },
    },
  };
  const bottomBarVariants = {
    hidden: { width: 0 },
    menuClosed: {
      width: "auto",
    },
    menuOpen: {
      width: [0, 0, 32],
      rotate: [0, 0, -45],
      translateY: [0, 0, -6],
      transition: {
        delay: 0,
        duration: 0.1,
        type: "spring",
        mass: 0.01,
        stiffness: 10,
        damping: 1,
      },
    },
  };

  useEffect(() => {
    console.log(mobileMenuOpen);
  }, [mobileMenuOpen]);

  return (
    <button
      className="block slow-transition z-50 w-12 box-content"
      id="mobileMenuToggle"
      onClick={toggleMobileMenu}
      aria-label={`Mobile menu toggle`}
    >
      <motion.span
        variants={topBarVariants}
        initial="hidden"
        animate={mobileMenuOpen ? "menuOpen" : "menuClosed"}
        className="h-1 block bg-stone-dark dark:bg-white rounded-full m-2 slow-transition"
      ></motion.span>
      <motion.span
        variants={bottomBarVariants}
        initial="hidden"
        animate={mobileMenuOpen ? "menuOpen" : "menuClosed"}
        className="h-1 block bg-stone-dark dark:bg-white rounded-full m-2 slow-transition"
      ></motion.span>
    </button>
  );
};

export default MobileMenuToggle;
