import MobileMenuContext from "../contexts/MobileMenuContext";
import React, { useState } from "react";

const MobileMenuProvider = ({ children }: MobileMenuProviderProps) => {
  // The mobile menu is closed be default.
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!mobileMenuOpen);
  };

  return (
    <MobileMenuContext.Provider value={{ mobileMenuOpen, toggleMobileMenu }}>
      {children}
    </MobileMenuContext.Provider>
  );
};

interface MobileMenuProviderProps {
  children: React.ReactNode;
}

export default MobileMenuProvider;
