import React, { useEffect, useRef } from "react";
import useWindowSize from "../hooks/useWindowSize";
import { useLocation } from "@reach/router";

const SmoothScrollWrapper = ({ children }: IProps) => {
  const location = useLocation();
  const innerWrapper = useRef<HTMLDivElement>(null);
  const { height, width } = useWindowSize();
  const [skewData, setSkewData] = React.useState<ISkewData>({
    ease: 0.05,
    current: 0,
    previous: 0,
    rounded: 0,
  });

  // Calculates the velocity of the scroll and translates page content accordingly
  const skewScroll = () => {
    skewData.current = window.pageYOffset;
    skewData.previous += (skewData.current - skewData.previous) * skewData.ease;
    skewData.rounded = Math.round(skewData.previous * 100) / 100;

    const difference = skewData.current - skewData.rounded;
    const acceleration = difference / width;
    const skew = acceleration * 20;

    if (innerWrapper.current) {
      innerWrapper.current.style.transform = `translate3d(0, -${skewData.rounded}px, 0) skewY(${skew}deg)`;
    }

    requestAnimationFrame(() => skewScroll());
  };

  // Skew scrolling
  useEffect(() => {
    requestAnimationFrame(() => skewScroll());
  }, []);

  useEffect(() => {
    setBodyHeight();
  }, [height, location]);

  const setBodyHeight = () => {
    if (innerWrapper.current) {
      const innerWrapperHeight = innerWrapper.current.children[0].clientHeight;

      document.body.style.height = `${innerWrapperHeight}px`;
    }
  };

  return (
    <div className="fixed top-0 left-0 h-screen w-screen overflow-hidden">
      <div ref={innerWrapper}>{children}</div>
    </div>
  );
};

interface IProps {
  children: React.ReactNode;
}

interface ISkewData {
  ease: number;
  current: number;
  previous: number;
  rounded: number;
}

export default SmoothScrollWrapper;
