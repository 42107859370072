export const slideInDown = {
  hidden: {
    opacity: 0,
    y: -25,
  },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.2,
      type: "spring",
      stiffness: 400,
      damping: 100,
      mass: 0.1,
    },
  },
};

export const slideInRight = {
  hidden: {
    opacity: 0,
    x: -25,
  },
  visible: {
    opacity: 1,
    x: 0,
    transition: {
      duration: 0.2,
      type: "spring",
      stiffness: 400,
      damping: 100,
      mass: 0.1,
    },
  },
};

// Outlines the svg path, then fills it in at the end
export const svgOutlineThenFill = {
  hidden: {
    pathLength: 0,
    fillOpacity: 0,
  },
  visible: {
    pathLength: [0, 1, 1],
    fillOpacity: [0, 0, 0, 1],
    transition: {
      delay: 0.1,
      duration: 3,
      ease: [0.6, 0.05, -0.01, 0.9],
    },
  },
};
