import React from "react";
import { motion } from "framer-motion";
import { slideInDown } from "../../../styles/animations/framer_animations";
import { Link } from "gatsby";
import { useLocation } from "@reach/router";

const transition = {
  duration: 0.8,
  ease: [0.6, 0.05, -0.01, 0.9],
};

const NavItem = ({ path, name }: IProps) => {
  const location = useLocation();
  const [selected, setSelected] = React.useState(false);

  React.useEffect(() => {
    if (location.pathname === path) {
      setSelected(true);
    } else {
      setSelected(false);
    }
  }, [location.pathname, path]);

  return (
    <>
      <motion.div
        variants={slideInDown}
        whileHover={{
          scale: 1.1,
          transition: { delay: 0, duration: 0.1, ease: "linear" },
        }}
        className="px-3 antialiased text-stone dark:text-white hover:dark:text-stone-light hover:text-stone-dark select-none flex flex-col justify-around font-body uppercase"
        transition={transition}
      >
        <Link
          to={path}
          activeClassName="bg-clip-text text-transparent gradient-bg dark:gradient-bg"
          aria-label={`Link to ${name}`}
        >
          {name}
        </Link>
        {selected && (
          <motion.div
            className="h-px w-full bg-stone-dark dark:bg-white"
            layoutId="underline"
          />
        )}
      </motion.div>
    </>
  );
};

interface IProps {
  path: string;
  name: string;
  selected?: boolean;
}

export default NavItem;
