import React from "react";
import { AnimatePresence, motion } from "framer-motion";
import { navItems } from "./nav_items";
import MobileNavItem from "./MobileNavItem";

const MobileMenu = () => {
  const mobileMenuVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.3,
        delaychildren: 0.5,
      },
    },
    exit: {
      opacity: 0,
    },
  };

  return (
    <>
      <motion.div
        variants={mobileMenuVariants}
        initial="hidden"
        animate="visible"
        exit="exit"
        className="fixed top-0 left-0 h-screen w-screen bg-white dark:bg-black text-stone-dark dark:text-white z-40 grid place-content-center"
      >
        <AnimatePresence exitBeforeEnter>
          {navItems.map((navItem, index) => {
            return (
              <MobileNavItem
                key={index}
                path={navItem.path}
                name={navItem.name}
              />
            );
          })}
        </AnimatePresence>
      </motion.div>
    </>
  );
};

export default MobileMenu;
