import useDarkMode from "../hooks/useDarkMode";
import React from "react";
import { BsMoonFill, BsSunFill } from "react-icons/bs";
import { motion } from "framer-motion";

function DarkModeToggle({ animationVariants }: IProps) {
  const { darkMode, toggleDarkMode } = useDarkMode();

  return (
    <motion.button
      variants={animationVariants}
      onClick={toggleDarkMode}
      className="text-lg antialiased mr-2 bg-transparent hover:cursor-pointer text-stone-dark dark:text-white"
      aria-label="Toggle dark mode"
    >
      {darkMode ? <BsSunFill /> : <BsMoonFill />}
    </motion.button>
  );
}

interface IProps {
  animationVariants?: any;
}

export default DarkModeToggle;
