import React, { useState } from "react";
import { motion } from "framer-motion";
import { svgOutlineThenFill } from "../styles/animations/framer_animations";

const HourGlassLogo = ({ loaderAnimation = false }: IProps) => {
  const [hover, setHover] = useState(false);

  const svgVariants = {
    hidden: {
      opacity: 0,
    },
    visible: {
      opacity: 1,
    },
    hover: {
      rotateX: 360,
    },
  };

  const svgLoaderAnimation = {};

  const logoRotationAnimation = {};

  return (
    <>
      <motion.svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 250 250"
        height="100%"
        variants={svgVariants}
        className="origin-center"
        initial="hidden"
        animate="visible"
      >
        <motion.polygon
          variants={loaderAnimation ? svgLoaderAnimation : svgOutlineThenFill}
          className="dark:stroke-white stroke-stone-dark dark:fill-white fill-stone-dark"
          style={{ strokeWidth: 3 }}
          points="125 0 0 0 0 21.64 80.56 125 80.56 125 0 228.37 0 250 125 250 125 222.03 46.45 222.03 122.08 125 122.08 125 46.45 27.97 125 27.97 125 0"
        />
        <motion.polygon
          variants={loaderAnimation ? svgLoaderAnimation : svgOutlineThenFill}
          className="dark:stroke-white stroke-stone-dark dark:fill-white fill-stone-dark"
          style={{ strokeWidth: 3 }}
          points="250 21.64 250 0 125 0 125 27.97 203.55 27.97 127.92 125 127.92 125 203.55 222.03 125 222.03 125 250 250 250 250 228.37 169.44 125 169.44 125 250 21.64"
        ></motion.polygon>
      </motion.svg>
    </>
  );
};

interface IProps {
  loaderAnimation?: boolean;
}

export default HourGlassLogo;
