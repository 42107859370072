import { useState, useEffect } from "react";

const useWindowSize = () => {
  const getWindowSize = () => {
    return {
      width: window.innerWidth,
      height: window.innerHeight,
    };
  };

  const [windowSize, setWindowSize] = useState(getWindowSize());

  // Update on resize
  useEffect(() => {
    function setFromEvent() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener("resize", setFromEvent);

    return () => window.removeEventListener("resize", setFromEvent);
  }, []);

  return windowSize;
};

export default useWindowSize;
