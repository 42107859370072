import "@fontsource/apfel-grotezk";
import "@fontsource/montserrat";
import "./src/styles/global.scss";
import React from "react";
import Layout from "./src/layouts/Layout";
import { AnimatePresence } from "framer-motion";

// Resolves the user's preferred color scheme.
// If the user has explicitly chosen a color scheme in the past,
// then that color scheme is used. If the user has not chosen a
// color scheme, then the OS's preferred color scheme is used. If neither
// of those apply, then the light color scheme is used.
// Returns true if dark mode is enabled, false otherwise.
const userPreferDarkMode = (): string => {
  if (
    localStorage.darkMode === "true" ||
    window?.matchMedia("(prefers-color-scheme: dark)")?.matches
  ) {
    document.documentElement.setAttribute("data-theme", "dark");
    return "true"; // dark mode
  } else {
    document.documentElement.setAttribute("data-theme", "light");
    return "false"; // light mode
  }
};

// Check if local storage is available
function storageAvailable(type) {
  let storage;
  try {
    storage = window[type];
    const x = "__storage_test__";
    storage.setItem(x, x);
    storage.removeItem(x);
    return true;
  } catch (e) {
    return (
      e instanceof DOMException &&
      // everything except Firefox
      (e.code === 22 ||
        // Firefox
        e.code === 1014 ||
        // test name field too, because code might not be present
        // everything except Firefox
        e.name === "QuotaExceededError" ||
        // Firefox
        e.name === "NS_ERROR_DOM_QUOTA_REACHED") &&
      // acknowledge QuotaExceededError only if there's something already stored
      storage &&
      storage.length !== 0
    );
  }
}

export const onClientEntry = () => {
  // Save the user's preferred color scheme in localStorage if available.
  if (storageAvailable("localStorage")) {
    if (localStorage.darkMode === undefined) {
      localStorage.setItem("darkMode", userPreferDarkMode());
    }
  } else {
    console.log(
      "localStorage is not available; theme preference will not be saved"
    );
  }
};

export const wrapPageElement = ({ element }) => {
  return <Layout>{element}</Layout>;
};
