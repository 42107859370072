module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-29N01XG9XS"],"pluginConfig":{"head":true}},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Zach Mumbauer","short_name":"Zach Mumbauer","description":"Zach Mumbauer is an experienced full-stack software engineer based in the Boston area. He has a passion for mission driven companies, specifically in innovative and disruptive healthcare technology.","start_url":"/","icon":"src/images/hourglass.svg","theme_color":"#3d3d3d","background_color":"#f7f0eb","theme_color_in_head":false,"legacy":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"5d56f095e279c4e600a78c1a1217f542"},
    },{
      plugin: require('../node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://www.zmumbauer.com"},
    },{
      plugin: require('../gatsby-browser.tsx'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
