import { graphql, useStaticQuery } from "gatsby";
import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import useDarkMode from "../../hooks/useDarkMode";
import { useLocation } from "@reach/router";

const query = graphql`
  {
    site {
      siteMetadata {
        title
        description
        siteUrl
        og {
          siteName
          twitterHandle
        }
      }
    }
  }
`;

const SEO = ({ title, description, keywords }: IProps) => {
  const location = useLocation();
  const { site, featuredImage } = useStaticQuery(query);
  const metaTitle = title || site.siteMetadata.title;
  const metaDescription = description || site.siteMetadata.description;
  const { darkMode } = useDarkMode();
  const domain =
    process.env.NODE_ENV == "production"
      ? "https://www.zmumbauer.com/"
      : "localhost:8000";

  const metas = [
    { name: "description", content: metaDescription },
    {
      name: "viewport",
      content: "width=device-width, initial-scale=1, shink-to-fit=no",
    },
    {
      name: "theme-color",
      content: `${darkMode ? "#000" : "#fff"}`,
    },
    {
      name: "msapplication-TileColor",
      content: `#000000`,
    },
    {
      name: "og:image",
      content: `${domain}icons/icon-512x512.png`,
    },
    {
      name: "og:type",
      content: "website",
    },
    {
      name: "og:title",
      content: metaTitle,
    },
    {
      name: "og:description",
      content: metaDescription,
    },
    {
      name: "og:site_name",
      content: site!.siteMetadata!.og!.siteName,
    },
    {
      name: "og:url",
      content: `${site?.siteMetadata?.siteUrl}${location.pathname}`,
    },
    {
      name: "twitter:card",
      content: "summary_large_image",
    },
    {
      name: "twitter:description",
      content: description,
    },
    {
      name: "twitter:title",
      content: title,
    },
    {
      name: "twitter:image",
      // content: ogImage.images.fallback.src,
    },
    {
      name: "twitter:creator",
      content: site!.siteMetadata!.og!.twitterHandle,
    },
  ];

  if (keywords) {
    metas.push({
      name: "keywords",
      content: keywords,
    });
  }

  return (
    <Helmet title={metaTitle} htmlAttributes={{ lang: "en" }} meta={metas} />
  );
};

interface IProps {
  title?: string;
  description?: string;
  keywords?: string;
}

export default SEO;
