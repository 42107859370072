import React, { useEffect } from "react";
import { Link } from "gatsby";
import { AnimatePresence, motion } from "framer-motion";

import NavItem from "./NavItem";
import HourGlassLogo from "../../HourGlassLogo";
import DarkModeToggle from "../../DarkModeToggle";
import MobileMenuToggle from "../MobileMenuToggle";
import useMobileMenu from "../../../hooks/useMobileMenu";

import { slideInDown } from "../../../styles/animations/framer_animations";

import { navItems } from "./nav_items";
import MobileMenu from "./MobileMenu";

const navBarVariants = {
  hidden: {},
  visible: {
    transition: {
      staggerChildren: 0.3,
      delaychildren: 0.5,
    },
  },
};

function NavBar() {
  const { mobileMenuOpen, toggleMobileMenu } = useMobileMenu();

  return (
    <nav className="flex flex-row flex-nowrap top-0 pt-0 dark:text-white slow-transition h-10">
      <Link
        to="/"
        className="text-xl sm:text-2xl font-bold mr-10 antialiased"
        aria-label="Link to home"
      >
        <HourGlassLogo />
      </Link>
      <div className="ml-auto flex flex-row flex-nowrap m-1">
        <div className="mobile-links flex md:hidden">
          <DarkModeToggle />
          <MobileMenuToggle />
        </div>

        <AnimatePresence exitBeforeEnter>
          {mobileMenuOpen && <MobileMenu />}
        </AnimatePresence>

        <motion.div
          variants={navBarVariants}
          initial="hidden"
          animate="visible"
          className="desktop-links hidden md:flex"
        >
          <DarkModeToggle animationVariants={slideInDown} />
          {!mobileMenuOpen &&
            navItems.map((navItem, index) => {
              return (
                <NavItem key={index} path={navItem.path} name={navItem.name} />
              );
            })}
        </motion.div>
      </div>
    </nav>
  );
}

export default NavBar;
