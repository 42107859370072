import React from "react";
import NavBar from "./navbar/NavBar";

function Header() {
  return (
    <>
      <div className="mb-20">
        <NavBar />
      </div>
    </>
  );
}

export default Header;
