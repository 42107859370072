export interface Navitem {
  name: string;
  link: string;
}

export const navItems = [
  { name: "Home", path: "/" },
  { name: "About", path: "/about/" },
  { name: "Resume", path: "/resume/" },
  { name: "Contact", path: "/contact/" },
];
