import React from "react";
import { motion } from "framer-motion";
import { Link } from "gatsby";
import useMobileMenu from "../../../hooks/useMobileMenu";
import { slideInRight } from "../../../styles/animations/framer_animations";

const transition = {
  duration: 0.8,
  ease: [0.6, 0.05, -0.01, 0.9],
};

const MobileNavItem = ({ path, name }: IProps) => {
  const { mobileMenuOpen, toggleMobileMenu } = useMobileMenu();

  return (
    <>
      <motion.div
        onClick={toggleMobileMenu}
        variants={slideInRight}
        className="px-3 antialiased text-stone dark:text-white select-none flex flex-col justify-around font-body uppercase text-4xl mb-10"
        transition={transition}
        exit={{ x: 100 }}
      >
        <Link
          to={path}
          activeClassName="bg-clip-text text-transparent gradient-bg dark:gradient-bg"
          aria-label={`Link to ${name}`}
        >
          {name}
        </Link>
      </motion.div>
    </>
  );
};

interface IProps {
  path: string;
  name: string;
  selected?: boolean;
}

export default MobileNavItem;
